import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import MainFooter from "../../components/main/MainFooter";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";
import "../../styles/main/payment.css";

type Props = {
  move?: (page: string | number) => void;
  isActive?: boolean;
};

export default ({ move, isActive }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();
  const { isScrolling } = useScrolling();

  const swiperRef = useRef<any>(null);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const container1Animation = useAnimation();

  const inner1Animation = useAnimation();

  const line1Animation = useAnimation();

  const phone1Animation = useAnimation();
  const phone2Animation = useAnimation();
  const phone3Animation = useAnimation();
  const phone4Animation = useAnimation();
  const phone5Animation = useAnimation();

  const activeDots = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];

  const [ref, inView] = useInView();
  const [titleRef, titleInView] = useInView();

  const titleVariants: Variants = {
    offscreen: {
      x: -100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "keyframes",
        duration: 0.5,
      },
    },
  };

  useEffect(() => {
    if (inView && !isDesktop) {
      startAnimation();
    }

    if (isActive && isDesktop) {
      startAnimation();
    }
  }, [inView, titleInView, isActive]);

  const startAnimation = async () => {
    if (!isDesktop) {
      await line1Animation.start({ pathLength: 1 });
      await container1Animation.start({ opacity: 1 });
      await inner1Animation.start({ opacity: 1 });

      await phone1Animation.start({ opacity: 1 });
      await phone2Animation.start({ opacity: 1 });
      await phone3Animation.start({ opacity: 1 });
      await phone4Animation.start({ opacity: 1 });
    } else {
      await line1Animation.start({ pathLength: 1 });

      await phone1Animation.start({ opacity: 1 });
      await phone2Animation.start({ opacity: 1 });
      await phone3Animation.start({ opacity: 1 });

      await container1Animation.start({ opacity: 1 });
      await phone4Animation.start({ opacity: 1 });

      await phone5Animation.start({ opacity: 1 });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="payment-container" ref={ref}>
      {!isMobile && (
        <div>
          <div ref={titleRef}>
            <div className="payment-title-text font-bold">PAYMENT</div>
          </div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <svg width="361" height="114">
            <motion.path
              d="M360,0 v55 H180.5 v60"
              stroke="white"
              stroke-width="1"
              fill={"none"}
              initial={{ pathLength: 0 }}
              animate={line1Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1 }}
            />
          </svg>
        </div>
      )}
      {!isDesktop && (
        <div className="payment-image-container">
          <div className="payment-image-inner">
            <motion.div
              className="payment-image-box payment-container1"
              initial={{ opacity: 0 }}
              animate={container1Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.25 }}
            >
              <motion.div
                className="payment-inner1"
                initial={{ opacity: 0 }}
                animate={inner1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
            </motion.div>
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className="payment-scroll-vertical">
            <Swiper
              ref={swiperRef}
              onSlideChange={(e) => {
                setActiveIndex(e.activeIndex);
              }}
            >
              <SwiperSlide>
                <div className="payment-scroll-box">
                  <div className="payment-scroll-inner payment-scroll-inner1" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="payment-scroll-box">
                  <div className="payment-scroll-inner payment-scroll-inner2" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="payment-scroll-box">
                  <div className="payment-scroll-inner payment-scroll-inner3" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="payment-scroll-box">
                  <div className="payment-scroll-inner payment-scroll-inner4" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="payment-dot-container">
            <div className="payment-dots">
              {activeDots.map((dot) => {
                return (
                  <div
                    className={
                      dot.id === activeIndex
                        ? `payment-active-dot`
                        : `payment-in-active-dot`
                    }
                    onClick={() => {
                      swiperRef.current?.swiper.slideTo(dot.id);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}

      {isTablet && (
        // <div>
        <div className="payment-content-container">
          <div className="payment-content-inner">
            <div className="payment-phones-container">
              <motion.div
                className="payment-phones-inner payment-phone1"
                initial={{ opacity: 0 }}
                animate={phone1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
              <motion.div
                className="payment-phones-inner payment-phone2"
                initial={{ opacity: 0 }}
                animate={phone2Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
              <motion.div
                className="payment-phones-inner payment-phone3"
                initial={{ opacity: 0 }}
                animate={phone3Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
              <motion.div
                className="payment-phones-inner payment-phone4"
                initial={{ opacity: 0 }}
                animate={phone4Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
            </div>

            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div variants={titleVariants}>
                <div className="payment-title-title font-medium">
                  Free Fee ‘COCOCHAT’
                </div>
                <div className="payment-title-body font-light">
                  친구에게. 사업자에게
                  <br />
                  1초 결제
                  <br />
                  결제 수수료는
                  <br />
                  ‘코코챗’에서
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      )}

      {isDesktop && (
        <div className="payment-d-container-inner">
          <div className="payment-d-content-container">
            <div className="payment-d-phone-container">
              <motion.div
                className="payment-d-image-container-small payment-d-phone1"
                initial={{ opacity: 0 }}
                animate={phone1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25, delay: 0.25 }}
              />

              <motion.div
                className="payment-d-image-container-small payment-d-phone2"
                initial={{ opacity: 0 }}
                animate={phone2Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />

              <motion.div
                className="payment-d-image-container-small payment-d-phone3"
                initial={{ opacity: 0 }}
                animate={phone3Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />

              <motion.div
                className="payment-d-image-container-big"
                initial={{ opacity: 0 }}
                animate={container1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              >
                <motion.div
                  className="payment-d-image-container-big-inner payment-d-phone4"
                  initial={{ opacity: 0 }}
                  animate={phone4Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  {/* <svg width="361" height="114" style={{ marginBottom: "-2px" }}>
                    <motion.path
                      d="M360,0 v55 H180.5 v60"
                      stroke="white"
                      stroke-width="1"
                      fill={"none"}
                      initial={{ pathLength: 0 }}
                      animate={line1Animation}
                      viewport={{ once: true, amount: 0.8 }}
                      transition={{ duration: 1 }}
                    />
                  </svg> */}
                </motion.div>
              </motion.div>

              <motion.div
                className="payment-d-image-container-small payment-d-phone5"
                initial={{ opacity: 0 }}
                animate={phone5Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              />
            </div>

            <div className="payment-d-title-box">
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="payment-d-title-container">
                    <div className="payment-d-title-inner1 font-medium">
                      Free Fee ‘COCOCHAT’
                    </div>

                    <div className="payment-d-title-inner2 font-light">
                      친구에게. 사업자에게 1초 결제 결제 수수료는 ‘코코챗’에서
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <motion.div variants={titleVariants}>
            <div className="payment-title-title font-medium">
              Free Fee ‘COCOCHAT’
            </div>
            <div className="payment-title-body font-light">
              친구에게. 사업자에게
              <br />
              1초 결제
              <br />
              결제 수수료는
              <br />
              ‘코코챗’에서
            </div>
          </motion.div>
        </motion.div>
      )}
      <MainFooter
        active={3}
        footerMode={"dark"}
        textMode={"dark"}
        move={move}
      />
      {!isMobile && (
        <div
          className="payment-business cursor-pointer"
          onClick={() => {
            if (isScrolling) return;
            navigate("/inquiry");
          }}
        >
          <div className="payment-business-container">
            <div className="payment-business-line">
              <div className="payment-business-inner1 font-bold">
                메신저 의뢰 및 업무 제휴 문의
              </div>
              <div className="payment-business-arrow" />
            </div>
            <div className="payment-business-inner2" />
          </div>
        </div>
      )}
    </div>
  );
};
