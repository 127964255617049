import { motion } from "framer-motion";
import IntroductionFooter from "../../components/introduction/IntroductionFooter";
import useResponse from "../../hooks/useResponse";
import "../../styles/introduction/oneword.css";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  return (
    <div className="oneword-container">
      {!isMobile && <div className="description-title-text">ONE WORD</div>}
      <div className="oneword-content-container">
        <div className="oneword-small-dots" />
        <div className="oneword-big-dots" />

        <motion.div
          className="oneword-content"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{ duration: 1 }}
        >
          <div className="oneword-title font-bold">
            쉽고 빠른 금융을&nbsp;
            {!isDesktop && <br />}
            만들기 위해
            <br />
            코코챗은 언제나
            <br />
            노력하고 있습니다.
          </div>
        </motion.div>
      </div>
      <IntroductionFooter
        footerMode={"dark"}
        textMode={"dark"}
        active={3}
        move={move}
      />
    </div>
  );
};
