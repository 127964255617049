import "../../styles/main/messenger.css";

import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import MainFooter from "../../components/main/MainFooter";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();
  const { isScrolling } = useScrolling();

  const [mobileRef, inViewMobile] = useInView();
  const [tabletRef, inViewTablet] = useInView();
  const [desktopRef, inViewDesktop] = useInView();

  const videoRef = useRef<any>();

  const container1Animation = useAnimation();
  const container2Animation = useAnimation();
  const container3Animation = useAnimation();
  const container4Animation = useAnimation();

  const inner1Animation = useAnimation();
  const inner2Animation = useAnimation();
  const inner3Animation = useAnimation();
  const inner4Animation = useAnimation();

  const line1Animation = useAnimation();
  const line2Animation = useAnimation();
  const line3Animation = useAnimation();
  const line4Animation = useAnimation();

  useEffect(() => {
    if (
      (inViewMobile && isMobile) ||
      (inViewTablet && isTablet) ||
      (inViewDesktop && isDesktop)
    ) {
      startAnimation();
    }
  }, [inViewMobile, inViewTablet, inViewDesktop]);

  const startAnimation = async () => {
    await container1Animation.start({ opacity: 1 });
    inner1Animation.start({ opacity: 1 });
    if (!isDesktop) {
      await line1Animation.start({ pathLength: 1 });
    } else {
      await line1Animation.start({ width: "100%" });
    }

    await container2Animation.start({ opacity: 1 });
    inner2Animation.start({ opacity: 1 });
    if (!isDesktop) {
      await line2Animation.start({ pathLength: 1 });
    } else {
      await line2Animation.start({ width: "100%" });
    }

    await container3Animation.start({ opacity: 1 });
    inner3Animation.start({ opacity: 1 });
    if (!isDesktop) {
      await line3Animation.start({ pathLength: 1 });
    } else {
      await line3Animation.start({ width: "100%" });
    }

    await container4Animation.start({ opacity: 1 });
    inner4Animation.start({ opacity: 1 });
    if (!isDesktop) {
      await line4Animation.start({ pathLength: 1 });
    } else {
      await line4Animation.start({ width: "100%" });
    }
  };

  const setPlayBackRate = () => {
    videoRef.current.playbackRate = 1.5;
  };

  const navigate = useNavigate();

  const titleVariants: Variants = {
    offscreen: {
      x: -100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "keyframes",
        duration: 0.5,
      },
    },
  };

  return (
    <div className="messenger-container">
      {!isMobile && (
        <div className="messenger-title-text font-bold">MESSENGER</div>
      )}
      {isMobile && (
        <>
          <div ref={mobileRef}>
            <motion.div
              className="messenger-title-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div variants={titleVariants}>
                <div className="messenger-title-inner1 font-medium">
                  Daily. Work. Business ‘COCOCHAT’
                </div>
                <div className="messenger-title-inner2 font-light">
                  일상. 업무. 사업까지 ‘코코챗’으로 한 번에
                </div>
              </motion.div>
            </motion.div>

            <div className="messenger-image-container">
              <div className="messenger-image-box">
                <motion.div
                  className="messenger-container-short"
                  initial={{ opacity: 0 }}
                  animate={container1Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <motion.div
                    className="messenger-inner1"
                    initial={{ opacity: 0 }}
                    animate={inner1Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </motion.div>

                <svg width="1" height="42">
                  <motion.path
                    d="M0,0 L0,42"
                    stroke="#e5e5e5"
                    strokeWidth="1"
                    initial={{ pathLength: 0 }}
                    animate={line1Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </svg>

                <motion.div
                  className="messenger-container-long"
                  initial={{ opacity: 0 }}
                  animate={container2Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <motion.div
                    className="messenger-inner2"
                    initial={{ opacity: 0 }}
                    animate={inner2Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </motion.div>

                <svg width="1" height="42">
                  <motion.path
                    d="M0,0 L0,42"
                    stroke="#e5e5e5"
                    strokeWidth="2"
                    initial={{ pathLength: 0 }}
                    animate={line2Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                  />
                </svg>

                <motion.div
                  className="messenger-container-long"
                  initial={{ opacity: 0 }}
                  animate={container3Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <motion.div
                    className="messenger-inner3"
                    initial={{ opacity: 0 }}
                    animate={inner3Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </motion.div>

                <svg width="1" height="42">
                  <motion.path
                    d="M0,0 L0,42"
                    stroke="#e5e5e5"
                    strokeWidth="2"
                    initial={{ pathLength: 0 }}
                    animate={line3Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                  />
                </svg>

                <motion.div
                  className="messenger-container-long"
                  initial={{ opacity: 0 }}
                  animate={container4Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <motion.div
                    className="messenger-inner4"
                    initial={{ opacity: 0 }}
                    animate={inner4Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  >
                    {/* <video
                      src={process.env.PUBLIC_URL + "/app.mp4"}
                      autoPlay
                      loop
                      muted
                      className="messenger-inner4"
                      ref={videoRef}
                      onCanPlay={() => setPlayBackRate()}
                    /> */}
                  </motion.div>
                </motion.div>

                <svg width="1" height="100">
                  <motion.path
                    d="M0,0 L0,100"
                    stroke="#e5e5e5"
                    strokeWidth="2"
                    initial={{ pathLength: 0 }}
                    animate={line4Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                  />
                </svg>
              </div>
            </div>
          </div>
          <MainFooter active={2} footerMode={"white"} textMode={"white"} />
        </>
      )}

      {isTablet && (
        <div>
          <div className="messenger-t-title-container">
            <motion.div
              className="messenger-title-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div variants={titleVariants}>
                <div className="messenger-t-title-inner1 font-medium">
                  Daily. Work. Business ‘COCOCHAT’
                </div>
                <div className="messenger-t-title-inner2 font-light">
                  일상. 업무. 사업까지 ‘코코챗’으로 한 번에
                </div>
              </motion.div>
            </motion.div>
          </div>
          <motion.div className="messenger-t-box-container" ref={tabletRef}>
            <div className="messenger-t-box-container-inner">
              <motion.div
                className="messenger-t-image-container1"
                initial={{ opacity: 0 }}
                animate={container1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25, delay: 0.5 }}
              >
                <motion.div
                  className="messenger-t-image-inner-container1"
                  initial={{ opacity: 0 }}
                  animate={inner1Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <div className="messenger-t-image-inner-inner1" />
                </motion.div>
              </motion.div>

              <div className="messneger-t-line-container1">
                <svg width="40" height="1">
                  <motion.path
                    d="M0,0 L42,0"
                    stroke="#e5e5e5"
                    stroke-width="1"
                    initial={{ pathLength: 0 }}
                    animate={line1Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </svg>
              </div>

              <motion.div
                className="messenger-t-image-container2"
                initial={{ opacity: 0 }}
                animate={container2Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              >
                <motion.div
                  className="messenger-t-image-inner-container2"
                  initial={{ opacity: 0 }}
                  animate={inner2Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <div className="messenger-t-image-inner-inner2" />
                </motion.div>
              </motion.div>

              <div className="messneger-t-line-container2">
                <svg width="1" height="40">
                  <motion.path
                    d="M0,0 L0,30"
                    stroke="#e5e5e5"
                    stroke-width="1"
                    initial={{ pathLength: 0 }}
                    animate={line2Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </svg>
              </div>

              <motion.div
                className="messenger-t-image-container3"
                initial={{ opacity: 0 }}
                animate={container3Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              >
                <motion.div
                  className="messenger-t-image-inner-container3"
                  initial={{ opacity: 0 }}
                  animate={inner3Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <div className="messenger-t-image-inner-inner3" />
                </motion.div>
              </motion.div>

              <div className="messneger-t-line-container3">
                <svg width="40" height="1">
                  <motion.path
                    d="M42,0 L0,0"
                    stroke="#e5e5e5"
                    stroke-width="1"
                    initial={{ pathLength: 0 }}
                    animate={line3Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </svg>
              </div>

              <motion.div
                className="messenger-t-image-container4"
                initial={{ opacity: 0 }}
                animate={container4Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.25 }}
              >
                {/* <video
                  src={process.env.PUBLIC_URL + "/app.mp4"}
                  autoPlay
                  loop
                  muted
                  className="messenger-t-image-inner-inner4"
                  ref={videoRef}
                  onCanPlay={() => setPlayBackRate()}
                /> */}
                {/* <motion.div
                  className="messenger-t-image-inner-container4"
                  initial={{ opacity: 0 }}
                  animate={inner4Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 0.25 }}
                >
                  <div className="messenger-t-image-inner-inner4" />
                </motion.div> */}
              </motion.div>

              <div className="messneger-t-line-container4">
                <svg width="1" height="110">
                  <motion.path
                    d="M0,1 L0,110"
                    stroke="#e5e5e5"
                    stroke-width="1"
                    initial={{ pathLength: 0 }}
                    animate={line4Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                </svg>
              </div>
            </div>
          </motion.div>
          <MainFooter active={2} footerMode={"white"} textMode="white" />
        </div>
      )}

      {isDesktop && (
        <>
          <div className="messenger-d-content-container">
            <div className="messenger-d-content-inner">
              <div className="messenger-t-title-container">
                <motion.div
                  className="messenger-title-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <motion.div variants={titleVariants}>
                    <div className="messenger-t-title-inner1 font-medium">
                      Daily. Work. Business ‘COCOCHAT’
                    </div>
                    <div className="messenger-t-title-inner2 font-light">
                      일상. 업무. 사업까지 ‘코코챗’으로 한 번에
                    </div>
                  </motion.div>
                </motion.div>
              </div>

              <div className="messenger-d-image-container" ref={desktopRef}>
                <div className="messneger-d-image-inner">
                  <motion.div
                    className="messenger-d-image-container-small"
                    initial={{ opacity: 0 }}
                    animate={container1Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  >
                    <div className="messenger-d-image-box">
                      <motion.div
                        className="messenger-d-image-inner1"
                        initial={{ opacity: 0 }}
                        animate={inner1Animation}
                        viewport={{ once: true, amount: 0.8 }}
                        transition={{ duration: 0.25 }}
                      />
                    </div>
                  </motion.div>

                  <div className="messenger-d-line-container-long">
                    <motion.div
                      className="messenger-d-line"
                      initial={{ width: "0%" }}
                      animate={line1Animation}
                      viewport={{ once: true, amount: 0.8 }}
                      transition={{ duration: 0.25 }}
                    />
                  </div>

                  <motion.div
                    className="messenger-d-image-container-medium"
                    initial={{ opacity: 0 }}
                    animate={container2Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  >
                    <div className="messenger-d-image-box">
                      <motion.div
                        className="messenger-d-image-inner2"
                        initial={{ opacity: 0 }}
                        animate={inner2Animation}
                        viewport={{ once: true, amount: 0.8 }}
                        transition={{ duration: 0.25 }}
                      />
                    </div>
                  </motion.div>

                  <div className="messenger-d-line-container-short messenger-d-area3">
                    <motion.div
                      className="messenger-d-line"
                      initial={{ width: "0%" }}
                      animate={line2Animation}
                      viewport={{ once: true, amount: 0.8 }}
                      transition={{ duration: 0.25 }}
                    />
                  </div>

                  <motion.div
                    className="messenger-d-image-container-medium messenger-d-area3"
                    initial={{ opacity: 0 }}
                    animate={container3Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  >
                    <div className="messenger-d-image-box">
                      <motion.div
                        className="messenger-d-image-inner3"
                        initial={{ opacity: 0 }}
                        animate={inner3Animation}
                        viewport={{ once: true, amount: 0.8 }}
                        transition={{ duration: 0.25 }}
                      />
                    </div>
                  </motion.div>

                  <div className="messenger-d-line-container-long2 messenger-d-area4">
                    <motion.div
                      className="messenger-d-line"
                      initial={{ width: "0%" }}
                      animate={line3Animation}
                      viewport={{ once: true, amount: 0.8 }}
                      transition={{ duration: 0.25 }}
                    />
                  </div>

                  <motion.div
                    className="messenger-d-image-container-big messenger-d-area4"
                    initial={{ opacity: 0 }}
                    animate={container4Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  >
                    <div className="messenger-d-image-box">
                      <motion.div
                        className="messenger-d-image-inner4"
                        initial={{ opacity: 0 }}
                        animate={inner4Animation}
                        viewport={{ once: true, amount: 0.8 }}
                        transition={{ duration: 0.25 }}
                      >
                        {/* <video
                          src={process.env.PUBLIC_URL + "/app.mp4"}
                          autoPlay
                          muted
                          loop
                          className="messenger-d-image-inner4"
                          ref={videoRef}
                          onCanPlay={() => setPlayBackRate()}
                        /> */}
                      </motion.div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <MainFooter
            active={2}
            footerMode={"white"}
            textMode="white"
            move={move}
          />
        </>
      )}

      {!isMobile && (
        <div
          className="messenger-business cursor-pointer"
          onClick={() => {
            if (isScrolling) return;
            navigate("/inquiry");
          }}
        >
          <div className="messenger-business-container">
            <div className="messenger-business-line">
              <div className="messenger-business-inner1 font-bold">
                메신저 의뢰 및 업무 제휴 문의
              </div>
              <div className="messenger-business-arrow" />
            </div>
            <div className="messenger-business-inner2" />
          </div>
        </div>
      )}
    </div>
  );
};
