import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import NewsFooter from "../../components/news/NewsFooter";
import useResponse from "../../hooks/useResponse";
import "../../styles/inquiry/procedure.css";

type Props = {
  move?: (page: string | number) => void;
};

const Procedure = ({ move }: Props) => {
  const { isMobile, isDesktop } = useResponse();

  const [desktopRef, inViewDesktop] = useInView();

  const circle1Animation = useAnimation();
  const circle2Animation = useAnimation();
  const circle3Animation = useAnimation();

  const inner1Animation = useAnimation();
  const inner2Animation = useAnimation();
  const inner3Animation = useAnimation();

  const between1Animation = useAnimation();
  const between2Animation = useAnimation();

  const shortDelay = isDesktop ? 0 : 0.125;
  const middleDelay = isDesktop ? 0 : 0.25;
  const longDelay = isDesktop ? 0 : 0.5;

  const startAnimation = async () => {
    await circle1Animation.start({ opacity: 1 });
    await inner1Animation.start({ opacity: 1, y: 0 });

    await between1Animation.start({ opacity: 1 });

    await circle2Animation.start({ opacity: 1 });
    await inner2Animation.start({ opacity: 1, y: 0 });

    await between2Animation.start({ opacity: 1 });

    await circle3Animation.start({ opacity: 1 });
    await inner3Animation.start({ opacity: 1, y: 0 });
  };

  useEffect(() => {
    if (inViewDesktop && isDesktop) {
      startAnimation();
    }
  }, [inViewDesktop]);

  return (
    <div className="procedure-container">
      {!isMobile && (
        <div className="procedure-title-text font-bold" ref={desktopRef}>
          PROCEDURE
        </div>
      )}
      <div className="procedure-content">
        <div className="procedure-content-inner">
          <>
            <motion.div
              style={{ width: "100%" }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: shortDelay }}
            >
              <div className="procedure-title1 font-medium">
                Daily. Work. Business ‘COCOCHAT’
              </div>
              <div className="procedure-title2 font-light">
                사업자를 위한&nbsp;
                {!isDesktop && <br />}
                혁신적인 솔루션 ‘코코챗’
              </div>
            </motion.div>
            <motion.div
              className="procedure-title3 font-medium"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: middleDelay }}
            >
              ‘코코챗’은 편리하고 안전한 채팅 기능과 강력한 보안 결제 기능을
              결합하여, 소상공인의 소액 대금 결제, 모임비, 회비 등을 간편하게
              처리할 수 있는 최적의 도구입니다.
              <br />
              우리의 제휴 서비스를 통해 ‘코코챗’을 활용하여 더욱 효율적인
              비즈니스 운영과 편리한 금융 거래를 경험하세요.
            </motion.div>
          </>

          <div className="procedure-circle-container">
            <motion.div
              className="procedure-circle procedure-circle1"
              initial={{ opacity: 0 }}
              whileInView={!isDesktop ? { opacity: 1 } : {}}
              animate={circle1Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: shortDelay }}
            >
              <motion.div
                className="procedure-circle-inner1"
                initial={{ opacity: 0, y: 25 }}
                whileInView={!isDesktop ? { opacity: 1, y: 0 } : {}}
                animate={inner1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.5, delay: middleDelay }}
              >
                <div className="procedure-circle-text-inner1 font-medium">
                  1. 제안
                </div>
                <div className="procedure-circle-text-inner2 font-regular">
                  관련 내용과 문의 사항을
                  <br />
                  {process.env.REACT_APP_COMPANY_EMAIL}
                  <br />
                  으로 이메일로 보내주세요.
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              className="procedure-between-circle procedure-between-circle1"
              initial={{ opacity: 0 }}
              whileInView={!isDesktop ? { opacity: 1 } : {}}
              animate={between1Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: longDelay }}
            />

            <motion.div
              className="procedure-circle procedure-circle2"
              initial={{ opacity: 0 }}
              whileInView={!isDesktop ? { opacity: 1 } : {}}
              animate={circle2Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: shortDelay }}
            >
              <motion.div
                className="procedure-circle-inner2"
                initial={{ opacity: 0, y: 25 }}
                whileInView={!isDesktop ? { opacity: 1, y: 0 } : {}}
                animate={inner2Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.5, delay: middleDelay }}
              >
                <div className="procedure-circle-text-inner1 font-medium">
                  2. 검토
                </div>
                <div className="procedure-circle-text-inner2 font-regular">
                  등록하신 내용은
                  <br />
                  담당자가
                  <br />
                  상세하게 검토합니다.
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              className="procedure-between-circle procedure-between-circle2"
              initial={{ opacity: 0 }}
              whileInView={!isDesktop ? { opacity: 1 } : {}}
              animate={between2Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: longDelay }}
            />

            <motion.div
              className="procedure-circle procedure-circle3"
              initial={{ opacity: 0 }}
              whileInView={!isDesktop ? { opacity: 1 } : {}}
              animate={circle3Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.5, delay: shortDelay }}
            >
              <motion.div
                className="procedure-circle-inner3"
                initial={{ opacity: 0, y: 25 }}
                whileInView={!isDesktop ? { opacity: 1, y: 0 } : {}}
                animate={inner3Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.5, delay: middleDelay }}
              >
                <div className="procedure-circle-text-inner1 font-medium">
                  3. 결과회신
                </div>
                <div className="procedure-circle-text-inner2 font-regular">
                  내용의 검토가 완료되면,
                  <br />
                  연락처 or 이메일을 통해
                  <br />
                  결과를 회신드립니다.
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
      <NewsFooter
        footerMode={"white"}
        textMode={"white"}
        active={2}
        move={move}
      />
    </div>
  );
};

export default Procedure;
