import { motion } from "framer-motion";
import NewsFooter from "../../components/news/NewsFooter";
import useResponse from "../../hooks/useResponse";
import "../../styles/news/news.css";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  return (
    <div className="news-container">
      {!isMobile && <div className="news-title-text font-bold">NEWS</div>}
      <div className="news-content-inner">
        <div className="news-content">
          <motion.div
            className="news-title-container"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 0.75 }}
          >
            <div className="news-title-inner1 font-bold">New and Update</div>

            <div className="news-title-inner2 font-light">
              새소식 업데이트 소식
            </div>
          </motion.div>
          <motion.div
            className="news-cocochat-logo"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 1, delay: 0.25 }}
          />
        </div>
      </div>
      <NewsFooter
        footerMode={"white"}
        textMode={"white"}
        active={1}
        move={move}
      />
    </div>
  );
};
