import axios from "axios";

const api_home = axios.create({
  baseURL: "https://api-prod.app1.co.kr",
});

const api = axios.create({
  baseURL: "https://api-dev.app1.co.kr",
});

export const getNewsList = async () => {
  return api_home.get("/v1/home/news");
};

export const getPolicyString = async (
  title: "운영정책" | "전자상거래 표준약관" | "개인정보 처리방침"
) => {
  return api.get(`/v1/auth/policy?title=${title}`);
};
