import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import MainFooter from "../../components/main/MainFooter";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";
import "../../styles/main/withus.css";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop, isBigDesktop } = useResponse();
  const { isScrolling } = useScrolling();

  const navigate = useNavigate();

  return (
    <div className="withus-container">
      {!isMobile && <div className="withus-title-text font-bold">WITH US</div>}

      {isMobile && (
        <>
          <motion.div
            className="withus-title-container withus-title-container1"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <motion.div
              className="withus-title-inner1"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              <div className="withus-title-inner1-1 font-bold">코코챗</div>
              <div className="withus-title-inner1-2 font-light">COCOCHAT</div>
              <div className="withus-title-inner1-3 font-bold">
                코코챗과 함께라면
                <br />
                가족. 친구. 사업자. 파트너 누구라도
                <br />
                나의 소중한 데이터를 노출시키지 않고
                <br />
                편하게 이용하실 수 있습니다.
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className="withus-title-container withus-title-container2"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <motion.div
              className="withus-title-inner2"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              <div className="withus-title-inner2-1 font-bold">메신저</div>
              <div className="withus-title-inner2-2 font-light">MESSENGER</div>
              <div className="withus-title-inner2-3 font-bold">
                가족. 친구. 지인. 사업파트너와&nbsp;
                {!isBigDesktop && <br />}
                친구가 되어보세요.
                <br />
                <br />
                각종 소모임과 거래처 채팅 관리는 기본,
                <br />
                강력한 커뮤니케이션을 보장해요.
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            className="withus-title-container withus-title-container3"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <motion.div
              className="withus-title-inner3"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              <div className="withus-title-inner3-1 font-bold">결제</div>
              <div className="withus-title-inner3-2 font-light">PAYMENT</div>
              <div className="withus-title-inner3-3 font-bold">
                직관적인 UI로, 한눈에 보이는&nbsp;
                {!isBigDesktop && <br />}
                결제기록을 통해 {isBigDesktop && <br />}보다 빠르고 쉽게
                {!isBigDesktop && <br />}
                나의 금융을 관리하세요.
                <br />
                <br />
                평생 무료 결제로&nbsp;
                {!isBigDesktop && <br />}
                사용자의 추가 지출을 차단합니다.
                {!isBigDesktop && <br />}
              </div>
            </motion.div>
          </motion.div>
        </>
      )}

      {isTablet && (
        <div className="withus-t-container">
          <div className="withus-t-container-inner">
            <motion.div
              className="withus-title-container withus-title-container1"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <motion.div
                className="withus-title-inner1"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1, delay: 0.7 }}
              >
                <div className="withus-title-inner1-1 font-bold">코코챗</div>
                <div className="withus-title-inner1-2 font-light">COCOCHAT</div>
                <div className="withus-title-inner1-3 font-bold">
                  코코챗과 함께라면
                  <br />
                  가족. 친구. 사업자. 파트너 누구라도
                  <br />
                  나의 소중한 데이터를 노출시키지 않고
                  <br />
                  편하게 이용하실 수 있습니다.
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="withus-title-container withus-title-container2"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <motion.div
                className="withus-title-inner2"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1, delay: 0.7 }}
              >
                <div className="withus-title-inner2-1 font-bold">메신저</div>
                <div className="withus-title-inner2-2 font-light">
                  MESSENGER
                </div>
                <div className="withus-title-inner2-3 font-bold">
                  가족. 친구. 지인. 사업파트너와&nbsp;
                  {!isBigDesktop && <br />}
                  친구가 되어보세요.
                  <br />
                  <br />
                  각종 소모임과 거래처 채팅 관리는 기본,
                  <br />
                  강력한 커뮤니케이션을 보장해요.
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="withus-title-container withus-title-container3"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              <motion.div
                className="withus-title-inner3"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1, delay: 0.7 }}
              >
                <div className="withus-title-inner3-1 font-bold">결제</div>
                <div className="withus-title-inner3-2 font-light">PAYMENT</div>
                <div className="withus-title-inner3-3 font-bold">
                  직관적인 UI로, 한눈에 보이는&nbsp;
                  {!isBigDesktop && <br />}
                  결제기록을 통해 {isBigDesktop && <br />}보다 빠르고 쉽게
                  {!isBigDesktop && <br />}
                  나의 금융을 관리하세요.
                  <br />
                  <br />
                  평생 무료 결제로&nbsp;
                  {!isBigDesktop && <br />}
                  사용자의 추가 지출을 차단합니다.
                  {!isBigDesktop && <br />}
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      )}

      {isDesktop && (
        <div className="withus-d-container">
          <div className="withus-d-container-inner">
            <div className="withus-d-content-container">
              <div className="withus-title-box">
                <motion.div
                  className="withus-title-container withus-title-container1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <motion.div
                    className="withus-title-inner1"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <div className="withus-title-inner1-1 font-bold">
                      코코챗
                    </div>
                    <div className="withus-title-inner1-2 font-light">
                      COCOCHAT
                    </div>
                    <div className="withus-title-inner1-3 font-bold">
                      코코챗과 함께라면
                      <br />
                      가족. 친구. 사업자. 파트너 누구라도
                      <br />
                      나의 소중한 데이터를 노출시키지 않고
                      <br />
                      편하게 이용하실 수 있습니다.
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <div className="withus-title-box">
                <motion.div
                  className="withus-title-container withus-title-container3"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <motion.div
                    className="withus-title-inner3"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <div className="withus-title-inner3-1 font-bold">결제</div>
                    <div className="withus-title-inner3-2 font-light">
                      PAYMENT
                    </div>
                    <div className="withus-title-inner3-3 font-bold">
                      직관적인 UI로, 한눈에 보이는&nbsp;
                      {!isBigDesktop && <br />}
                      결제기록을 통해 {isBigDesktop && <br />}보다 빠르고 쉽게
                      {!isBigDesktop && <br />}
                      나의 금융을 관리하세요.
                      <br />
                      <br />
                      평생 무료 결제로&nbsp;
                      {!isBigDesktop && <br />}
                      사용자의 추가 지출을 차단합니다.
                      {!isBigDesktop && <br />}
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <div className="withus-title-box">
                <motion.div
                  className="withus-title-container withus-title-container2"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <motion.div
                    className="withus-title-inner2"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <div className="withus-title-inner2-1 font-bold">
                      메신저
                    </div>
                    <div className="withus-title-inner2-2 font-light">
                      MESSENGER
                    </div>
                    <div className="withus-title-inner2-3 font-bold">
                      가족. 친구. 지인. 사업파트너와&nbsp;
                      {!isBigDesktop && <br />}
                      친구가 되어보세요.
                      <br />
                      <br />
                      각종 소모임과 거래처 채팅 관리는 기본,
                      <br />
                      강력한 커뮤니케이션을 보장해요.
                    </div>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainFooter
        active={4}
        footerMode={isMobile ? "trans" : "white"}
        textMode={isMobile ? "dark" : "white"}
        move={move}
      />

      {!isMobile && (
        <div
          className="withus-business  cursor-pointer"
          onClick={() => {
            if (isScrolling) return;
            navigate("/inquiry");
          }}
        >
          <div className="withus-business-container">
            <div className="withus-business-line">
              <div className="withus-business-inner1 font-bold">
                메신저 의뢰 및 업무 제휴 문의
              </div>
              <div className="withus-business-arrow" />
            </div>
            <div className="withus-business-inner2" />
          </div>
        </div>
      )}
    </div>
  );
};
