import { format } from "date-fns";

//delay
export const delay = (ms = 0) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(1);
    }, ms);
  });
};

export function fDate(date: Date | string | number) {
  return format(new Date(date), "yyyy.MM.dd");
}
