import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaqFooter from "../../components/faq/FaqFooter";
import IntroductionFooter from "../../components/introduction/IntroductionFooter";
import MainFooter from "../../components/main/MainFooter";
import NewsFooter from "../../components/news/NewsFooter";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";
import "../../styles/main/cocochat.css";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();
  const [path, setPath] = useState("");

  const location = useLocation();

  const navigate = useNavigate();

  const { isScrolling } = useScrolling();

  useEffect(() => {
    const newPath = location.pathname.replace("/", "");
    setPath(newPath);
  }, [location]);

  return (
    <div className="cocochat-container">
      {!isMobile && (
        <div className="cocochat-title-text font-bold">COCOCHAT</div>
      )}
      {
        <>
          <div className="big-dots" />
          <div className="small-dots" />
          <div className="cocochat-content">
            <div className="cocochat-title font-light">
              {process.env.REACT_APP_COMPANY_NAME}
            </div>
            <div className="cocochat-flex">
              <div>
                <div className="cocochat-container1">
                  <div className="cocochat-inner-title1 font-medium">
                    사업자 등록번호
                  </div>
                  <div className="cocochat-inner-body1 font-light">
                    {process.env.REACT_APP_COMPANY_REGISTRATION_NUMBER}
                  </div>
                </div>

                <div className="cocochat-container2">
                  <div className="cocochat-inner-title2 font-medium">대표</div>
                  <div className="cocochat-inner-body2 font-light">
                    {process.env.REACT_APP_COMPANY_OWNER_NAME}
                  </div>
                </div>

                <div className="cocochat-container3">
                  <div className="cocochat-inner-title3 font-medium">
                    통신판매업 신고번호
                  </div>
                  <div className="cocochat-inner-body3 font-light">
                    2023-서울금천-1878
                  </div>
                </div>
              </div>

              <div>
                <div className="cocochat-container4">
                  <div className="cocochat-inner-title4 font-medium">주소</div>
                  <div className="cocochat-inner-body4 font-light">
                    {process.env.REACT_APP_COMPANY_ADDRESS}
                  </div>
                </div>

                <div className="cocochat-container5">
                  <div className="cocochat-inner-title5 font-medium">Tel.</div>
                  <div className="cocochat-inner-body5 font-light">
                    {process.env.REACT_APP_COMPANY_TEL}
                  </div>
                </div>

                <div className="cocochat-container6">
                  <div className="cocochat-inner-title6 font-medium">
                    E-mail
                  </div>
                  <div className="cocochat-inner-body6 font-light">
                    {process.env.REACT_APP_COMPANY_EMAIL}
                  </div>
                </div>
              </div>
            </div>

            <div className="cocochat-copyright font-medium">
              Copyright 2024. SUFIN All rights reserved.
            </div>

            <div className="cocochat-more">
              <div className="cocochat-more-line">
                <div
                  className="cocochat-more-inner1 font-regular cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/policy");
                  }}
                >
                  운영정책
                </div>
                <div
                  className="cocochat-more-inner2 font-medium cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/terms");
                  }}
                >
                  통합 서비스 이용약관
                </div>
                <div
                  className="cocochat-more-inner3 font-medium cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/privacy");
                  }}
                >
                  개인정보 취급방침
                </div>
              </div>

              {/* <div className="cocochat-more-line">
                <div className="cocochat-more-inner4 font-regular cursor-pointer">
                  이용자의 권리 및 유의사항
                </div>
                <div className="cocochat-more-inner5 font-regular cursor-pointer">
                  금융서비스 보호
                </div>
              </div> */}
            </div>
          </div>
          {path === "" && (
            <MainFooter
              footerMode={"white"}
              textMode={"white"}
              active={5}
              move={move}
            />
          )}

          {path === "introduction" && (
            <IntroductionFooter
              footerMode={"white"}
              textMode={"white"}
              active={4}
              move={move}
            />
          )}

          {(path === "news" || path === "inquiry") && (
            <NewsFooter
              footerMode={"white"}
              textMode={"white"}
              active={3}
              move={move}
            />
          )}

          {path === "faq" && (
            <FaqFooter
              footerMode={"white"}
              textMode={"white"}
              active={2}
              move={move}
            />
          )}
        </>
      }
    </div>
  );
};
