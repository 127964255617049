import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import useScrolling from "../../hooks/useScrolling";
import { onOpenMenu } from "../../redux/slices/menu";
import { onInActiveNews } from "../../redux/slices/news";
import "../../styles/news/header.css";
import { openStore } from "../../utils/open";

type Props = {
  color?: "white" | "black";
  goToTop?: () => void;
};

type NaviProps = {
  title: string;
  onClick?: () => void;
  color?: "white" | "black";
  isFirst?: boolean;
  isActive?: boolean;
};

export default ({ color, goToTop }: Props) => {
  const isDesktop = useMediaQuery({
    query: "(min-width:1024px)",
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const { isScrolling } = useScrolling();

  return (
    <div className="mobile-navigate">
      <div className="mobile-navigation-container">
        {isDesktop ? (
          <div className="header-navigation">
            <NaviElement
              title={"코코챗 소개"}
              color={color}
              isFirst
              onClick={() => {
                if (isScrolling) return;
                navigate("/introduction");
              }}
            />
            <NaviElement
              title={"뉴스"}
              color={color}
              onClick={() => {
                if (isScrolling) return;

                if (isDesktop && goToTop) {
                  navigate("/news");
                  goToTop();
                }

                if (location.pathname.includes("detail")) {
                  dispatch(onInActiveNews());
                  navigate("/news");
                }
                goToTop && goToTop();
              }}
              isActive
            />
            <NaviElement
              title={"제휴 문의"}
              color={color}
              onClick={() => {
                if (isScrolling) return;
                navigate("/inquiry");
              }}
            />
            <NaviElement
              title={"자주 묻는 질문"}
              color={color}
              onClick={() => {
                if (isScrolling) return;
                navigate("/faq");
              }}
            />
          </div>
        ) : (
          <>
            <div className="mobile-download cursor-pointer" onClick={openStore}>
              <span className="mobile-download-text font-bold">
                앱 다운로드
              </span>
            </div>
            {/* 햄버거 버튼 */}
            <div
              className="mobile-menu font-bold cursor-pointer"
              onClick={() => {
                dispatch(onOpenMenu(location.pathname));
              }}
            >
              <div className={`mobile-menu-inner-long black-menu`} />
              <div className={`mobile-menu-inner-long black-menu`} />
              <div className={`mobile-menu-inner-short black-menu`} />
            </div>
            {/* <div>{navigator.userAgent}</div> */}
          </>
        )}
      </div>
    </div>
  );
};

const NaviElement = ({ title, isFirst, onClick, isActive }: NaviProps) => {
  return (
    <div
      className={`header-d-navigation font-bold cursor-pointer ${
        isActive
          ? "news-header-d-navigation-active-black"
          : "news-header-d-navigation-black"
      } ${!isFirst && "header-d-navigation-margin"}`}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {title}
    </div>
  );
};
