import { Variants, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import IntroFooter from "../../components/main/MainFooter";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";
import "../../styles/main/intro.css";
import { openAppleStore, openGoogleStore, openStore } from "../../utils/open";

type Props = {
  ref: any;
  move: (page: string | number) => void;
};

const Intro = ({ ref, move }: any) => {
  const titleVariants: Variants = {
    offscreen: {
      x: -100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "keyframes",
        duration: 0.5,
      },
    },
  };

  const { isMobile, isTablet, isDesktop, isSmallDesktop } = useResponse();

  const navigate = useNavigate();

  const { isScrolling } = useScrolling();

  return (
    <div className="intro-container" ref={ref}>
      {!isMobile && <div className="intro-title-text font-bold">INTRO</div>}

      {isMobile && (
        <>
          <div className="intro-background-image">
            <motion.div
              className="intro-title"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div variants={titleVariants}>
                <div className="intro-title-inner1 font-medium">
                  Easy and Fast ‘COCOCHAT’
                </div>
                <div className="intro-title-inner2 font-light">
                  쉽고 빠르게 ‘코코챗’
                </div>
                <div className="intro-title-inner3 font-medium">
                  쉽고 빠른 금융을 만들기 위해 언제나 노력하고 있습니다.
                </div>
                <div
                  className="intro-download-button intro-download-button-span cursor-pointer font-bold"
                  onClick={openStore}
                >
                  앱 다운로드
                </div>
              </motion.div>
            </motion.div>
          </div>
          <IntroFooter active={1} footerMode={"trans"} textMode="dark" />
        </>
      )}

      {!isMobile && (
        <>
          <div className="intro-t-title-container">
            <motion.div
              className="intro-t-title-inner-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <motion.div variants={titleVariants}>
                <div className="intro-t-title-inner1 font-medium">
                  Easy and Fast ‘COCOCHAT’
                </div>
                <div className="intro-t-title-inner2 font-light">
                  쉽고 빠르게 {isTablet && <br />} ‘코코챗’
                </div>
                <div className="intro-t-title-inner3 font-medium">
                  쉽고 빠른 금융을 만들기 위해
                  {isTablet && <br />}
                  언제나 노력하고 있습니다.
                </div>
              </motion.div>
            </motion.div>
          </div>
          <div className="intro-t-download-container">
            <div className="intro-t-download-inner1 font-bold">다운로드</div>
            <div className="intro-download-button-container">
              <div
                className="intro-t-download-apple cursor-pointer"
                onClick={openAppleStore}
              />
              <div
                className="intro-t-download-google cursor-pointer"
                onClick={openGoogleStore}
              />
            </div>
            {/* <div className="intro-t-download-inner2 font-regular">클릭 시 이동됩니다.</div> */}
          </div>
          <div className="intro-t-image-container">
            <div className="intro-t-image-background">
              <div className="intro-t-image-inner-container">
                <div className="intro-t-image-inner-inner1 font-bold">
                  제휴 문의
                </div>
                <div className="intro-t-image-inner-inner2 font-medium">
                  {process.env.REACT_APP_COMPANY_ADDRESS}
                </div>
                <div className="intro-t-image-inner-inner3 font-bold">
                  Tel. {process.env.REACT_APP_COMPANY_TEL}
                </div>
                <div className="intro-t-image-inner-inner4 font-medium">
                  E-mail. {process.env.REACT_APP_COMPANY_EMAIL}
                </div>

                <div
                  className="intro-t-image-inner-business-container cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/inquiry");
                  }}
                >
                  <div className="intro-t-image-inner-business-line">
                    <div className="intro-t-image-inner-business1 font-bold">
                      메신저 의뢰 및 업무 제휴 문의
                    </div>

                    <div className="intro-t-image-inner-business2" />
                  </div>
                  <div className="intro-t-image-inner-business3" />
                </div>
              </div>
            </div>
          </div>
          {
            <IntroFooter
              active={1}
              footerMode={"dark"}
              textMode="dark"
              move={move}
            />
          }
        </>
      )}
    </div>
  );
};

export default Intro;
