import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import useScrolling from "../../hooks/useScrolling";
import { onOpenMenu } from "../../redux/slices/menu";
import "../../styles/introduction/header.css";
import { openStore } from "../../utils/open";

type Props = {
  color?: "white" | "black";
  goToTop: () => void;
};

type NaviProps = {
  title: string;
  onClick?: () => void;
  color?: "white" | "black";
  isFirst?: boolean;
  isActive?: boolean;
};

export default ({ color, goToTop }: Props) => {
  const isDesktop = useMediaQuery({
    query: "(min-width:1024px)",
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const { isScrolling } = useScrolling();

  const getColor = () => {
    if (color === "black") {
      return "black-menu";
    } else if (color === "white") {
      return "white-menu";
    } else {
      return "white-menu";
    }
  };

  return (
    <div className="mobile-navigate">
      <div className="mobile-navigation-container">
        {isDesktop ? (
          <div className="header-navigation">
            <NaviElement
              title={"코코챗 소개"}
              color={color}
              isFirst
              onClick={() => {
                if (isScrolling) return;
                navigate("/introduction");
              }}
            />
            <NaviElement
              title={"뉴스"}
              color={color}
              onClick={() => {
                if (isScrolling) return;
                navigate("/news");
              }}
            />
            <NaviElement
              title={"제휴 문의"}
              color={color}
              onClick={() => {
                if (isScrolling) return;
                navigate("/inquiry");
              }}
            />
            <NaviElement
              title={"자주 묻는 질문"}
              color={color}
              onClick={() => goToTop()}
              isActive
            />
          </div>
        ) : (
          <>
            <div className="mobile-download cursor-pointer" onClick={openStore}>
              <span className="mobile-download-text font-bold cursor-pointer">
                앱 다운로드
              </span>
            </div>
            {/* 햄버거 버튼 */}
            <div
              className="mobile-menu font-bold cursor-pointer"
              onClick={() => {
                dispatch(onOpenMenu(location.pathname));
              }}
            >
              <div className={`mobile-menu-inner-long ${getColor()}`} />
              <div className={`mobile-menu-inner-long ${getColor()}`} />
              <div className={`mobile-menu-inner-short ${getColor()}`} />
            </div>
            {/* <div>{navigator.userAgent}</div> */}
          </>
        )}
      </div>
    </div>
  );
};

const NaviElement = ({
  title,
  color,
  isFirst,
  onClick,
  isActive,
}: NaviProps) => {
  let naviClassName = "header-d-navigation font-bold";

  if (!isFirst) {
    naviClassName = naviClassName + " header-d-navigation-margin";
  }

  if (isActive) {
    if (color === "black") {
      naviClassName =
        naviClassName + ` ` + "introduction-header-d-navigation-active-black";
    } else if (color === "white") {
      naviClassName =
        naviClassName + ` ` + "introduction-header-d-navigation-active-white";
    }
  } else {
    if (color === "black") {
      naviClassName =
        naviClassName + ` ` + "introduction-header-d-navigation-black";
    } else if (color === "white") {
      naviClassName =
        naviClassName + ` ` + "introduction-header-d-navigation-white";
    }
  }

  return (
    <div
      className={naviClassName + " cursor-pointer"}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {title}
    </div>
  );
};
