import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INews } from "../../@types/type";
import { getNews } from "../../apis/services";
import NewsFooter from "../../components/news/NewsFooter";
import useNews from "../../hooks/useNews";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";
import { onChangeCategory, onChangePage } from "../../redux/slices/news";
import "../../styles/news/archive.css";

type Props = {
  move?: (page: string | number) => void;
  setSection?: any;
};

export default ({ move, setSection }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();
  const { isOpenNews, isActiveNews, newsDetail, newsCategory, newsPage } =
    useNews();
  const { isScrolling } = useScrolling();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const archiveRef = useRef<any>(null);

  const [news, setNews] = useState<INews[]>([]);
  const [isGetNews, setIsGetNews] = useState(0);

  const [currentNews, setCurrentNews] = useState<INews[]>([]);

  const [isNotFoundNews, setIsNotFoundNews] = useState<boolean>(false);

  const [isChangePage, setIsChangePage] = useState(false);

  const [pagination, setPagination] = useState<number[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const res: any = await getNews();
        setIsGetNews(isGetNews + 1);
        if (res.length <= 0) {
          setIsNotFoundNews(true);
        }
        setNews(res);
      } catch (err) {
        console.log("getNews error: " + err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (news.length <= 0) return;
      try {
        let newsArray: INews[] = news;

        switch (newsCategory) {
          case "NEWS":
            newsArray = news.filter((news) => news.type == "NEWS");
            break;

          case "EVENT":
            newsArray = news.filter((news) => news.type == "EVENT");
            break;

          default:
            break;
        }

        const pageCount = isTablet
          ? Math.ceil(newsArray.length / 10)
          : Math.ceil(newsArray.length / 3);
        const pageCounts = [];

        for (let i = 1; i <= pageCount; i++) {
          pageCounts.push(i);
        }

        setPagination(pageCounts);

        setCurrentNews(newsArray);
      } catch (err) {
        console.log("Archive error: " + err);
      }
    })();
  }, [news, newsCategory, newsPage]);

  useEffect(() => {
    if (isChangePage && !isDesktop) {
      moveToArchive();
    }
  }, [newsPage]);

  useEffect(() => {
    if (isActiveNews && isDesktop) {
      setSection();
    }

    if (!isActiveNews) {
      dispatch(onChangeCategory("ALL"));
      dispatch(onChangePage(1));
    }
  }, []);

  const moveToArchive = () => {
    archiveRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getRange = () => {
    let start = 0;
    let end = 2;

    if (!isTablet) {
      start = newsPage * 3 - 3;
      end = newsPage * 3 - 1;
    } else {
      start = newsPage * 10 - 10;
      end = newsPage * 10 - 1;
    }

    return [start, end];
  };

  const onChangeNewsPage = (_page: number) => {
    dispatch(onChangePage(_page));
    if (!isChangePage) {
      setIsChangePage(true);
    }
  };

  return (
    <div
      className={`archive-container ${
        !isGetNews && "archive-loading-container"
      }`}
      ref={archiveRef}
    >
      {!isMobile && <div className="archive-title-text font-bold">ARCHIVE</div>}

      {
        <div className="archive-content">
          <div className="archive-content-inner">
            <>
              <div className="archive-title1 font-medium">News Archive</div>
              <div className="archive-title2 font-light">뉴스 아카이브</div>
            </>

            <div className={`archive-category-container`}>
              <div className="archive-category-container2">
                <div
                  className={`archive-category-inner1 ${
                    newsCategory === "ALL"
                      ? "archive-category-active"
                      : "archive-category-inActive"
                  } cursor-pointer`}
                  onClick={() => {
                    dispatch(onChangeCategory("ALL"));
                  }}
                >
                  <div className="archive-category-inner-text font-bold">
                    전체
                  </div>
                </div>

                <div
                  className={`archive-category-inner2 ${
                    newsCategory === "NEWS"
                      ? "archive-category-active"
                      : "archive-category-inActive"
                  } cursor-pointer`}
                  onClick={() => {
                    dispatch(onChangeCategory("NEWS"));
                  }}
                >
                  <div className="archive-category-inner-text font-bold">
                    보도자료
                  </div>
                </div>

                <div
                  className={`archive-category-inner3 ${
                    newsCategory === "EVENT"
                      ? "archive-category-active"
                      : "archive-category-inActive"
                  } cursor-pointer`}
                  onClick={() => {
                    dispatch(onChangeCategory("EVENT"));
                  }}
                >
                  <div className="archive-category-inner-text font-bold">
                    이벤트
                  </div>
                </div>
              </div>
              {isDesktop && (
                <div className="archive-pagination">
                  {pagination.map((pagina, i) => {
                    return (
                      <div
                        className={`archive-pagination-inner ${
                          newsPage === pagina
                            ? "archive-pagination-inner-active"
                            : "archive-pagination-inner-inActive"
                        } cursor-pointer`}
                        onClick={() => {
                          dispatch(onChangePage(pagina));
                        }}
                        key={pagina}
                      >
                        <div
                          className={`archive-pagination-inner-text font-regular `}
                        >
                          {pagina}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="archive-news-container">
              {isNotFoundNews && (
                <div className="archive-news-box archive-news-box-null">
                  <div>뉴스가 존재하지 않습니다.</div>
                </div>
              )}

              {currentNews.map((news, i) => {
                if (getRange()[0] <= i && getRange()[1] >= i) {
                  return (
                    <div
                      className="archive-news-box cursor-pointer"
                      key={i}
                      onClick={() => {
                        if (isScrolling) return;
                        navigate(`/detail/${news.id}`);
                      }}
                    >
                      <div className="archive-news-category-container">
                        <div className="archive-news-category-logo" />
                        <div className="archive-news-category-title font-regular">
                          {news.type === "EVENT" ? "이벤트" : "보도자료"}
                        </div>
                      </div>
                      <div className="archive-news-title-container">
                        <div className="archive-news-title-inner font-bold">
                          {news.title}
                        </div>
                      </div>
                      {news.thumbnailUrl && (
                        <div
                          className="archive-news-image"
                          style={{
                            backgroundImage: `url(${news.thumbnailUrl})`,
                          }}
                        />
                      )}
                    </div>
                  );
                }
              })}
              {/* 뉴스 박스 */}
            </div>

            {!isDesktop && (
              <div className="archive-pagination">
                {pagination.map((pagina, i) => {
                  return (
                    <div
                      className={`archive-pagination-inner ${
                        newsPage === pagina
                          ? "archive-pagination-inner-active"
                          : "archive-pagination-inner-inActive"
                      } cursor-pointer`}
                      onClick={() => {
                        onChangeNewsPage(pagina);
                      }}
                      key={pagina}
                    >
                      <div
                        className={`archive-pagination-inner-text font-regular `}
                      >
                        {pagina}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      }
      <NewsFooter
        footerMode={"white"}
        textMode={"white"}
        active={2}
        move={move}
      />
    </div>
  );
};
